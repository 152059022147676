<script setup lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n-bridge"
import { mapState } from "pinia"
import { useFlightStore } from "../stores/flight"
import { useMessageStore } from "../stores/messages"

const { t } = useI18n()
</script>

<template>
  <v-footer v-if="isEmergency" height="56" padless app>
    <Emergency>{{ emergencyMessage }}</Emergency>
  </v-footer>
  <v-bottom-navigation v-else color="primary" height="56" grow app>
    <v-btn to="/">
      <span>{{ t("nav.home") }}</span>

      <v-icon>$mdi-home</v-icon>
    </v-btn>

    <v-btn to="/flight">
      <v-badge
        v-if="
          isDepartureUpdated ||
          isDestinationUpdated ||
          isPublicStatusUpdated ||
          isGateUpdated
        "
        :label="t('nav.status_or_gate_updated')"
        color="#00C0F3"
        dot
        offset-x="-20"
        offset-y="-30"
      >
      </v-badge>
      <span>{{ t("nav.flight_info") }}</span>

      <v-icon>$mdi-airplane-takeoff</v-icon>
    </v-btn>

    <v-btn to="/messages">
      <v-badge
        color="warning"
        :content="unreadMessageCount"
        :value="unreadMessageCount > 0"
        :label="t('nav.unread_messages', { messages: unreadMessageCount })"
        :offset-x="-20"
        :offset-y="-25"
      >
      </v-badge>
      <span>{{ t("nav.messages") }}</span>

      <v-icon>$mdi-message-text-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts">
export default defineComponent({
  computed: {
    ...mapState(useFlightStore, [
      "isDepartureUpdated",
      "isDestinationUpdated",
      "isPublicStatusUpdated",
      "isGateUpdated",
    ]),
    ...mapState(useMessageStore, ["getMessages", "getEmergencyMessages"]),

    isEmergency(): boolean {
      return this.getEmergencyMessages.length > 0
    },

    emergencyMessage() {
      if (this.isEmergency) {
        const { MessageText = "", message_french = "" } =
          this.getEmergencyMessages[0]
        if (this.$i18n.locale === "fr" && message_french) {
          return message_french
        }
        return MessageText
      }
      return ""
    },

    unreadMessageCount() {
      return this.getMessages.filter((m) => m.localFlagRead === false).length
    },
  },
})
</script>

