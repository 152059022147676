<template />

<script lang="ts">
import { mapState, mapActions } from "pinia"
import { useFlightStore, FIDS_MESSAGE } from "../stores/flight"
import {
  useMessageStore,
  BOARD_GATE_STATUS_MESSAGE,
  CANNED_MESSAGE,
  CUSTOM_MESSAGE,
  ACTION_REMOVE,
  MESSAGE_TYPE_60,
  MESSAGE_TYPE_70,
  EVENT_TYPE_SPECIAL_FLIGHT,
} from "../stores/messages"
import { useBackendStore } from "../stores/backend"
import { userContactsStore } from "../stores/userContacts"
import { sleep } from "@/utils/api"

export default {
  name: "SocketConnect",

  data() {
    return {
      autoReconnect: true,
      autoReconnectInProgress: false,
      socketReconnectTimeout: 2000, // ms

      keepAliveId: 0,
      keepAliveInterval: 540000, // ms

      requestTimeout: 60000, // ms

      failedCaptchaToken: false,

      gate: "",
      flightId: "",
      token: "",

      socket: {},

      errorTimeoutId: null,

      currentRecoveryAttempt: 0,
      maxRecoveryAttempts: 4,
      initialRecoveryDelay: 800, // ms
    }
  },

  created() {
    this.$root.$on("connectWebSocket", async (payload: any) => {
      await this.waitForClosedSocket()

      const { flightId = "", gate = "" } = payload
      // remove spaces, tabs and new lines
      // and convert to UPPER CASE
      this.gate = gate.replace(/\s+/g, "").toUpperCase()
      this.flightId = flightId.replace(/\s+/g, "").toUpperCase()

      // reset attempts
      this.currentRecoveryAttempt = 0

      this.connect()
    })
    this.$root.$on("disconnectWebSocket", (payload: any) => {
      const {
        clearContext = true,
        clearStatus = true,
        clearSocket = true,
        reconnectSocket = true,
        forceReconnection = false,
      } = payload
      if (clearContext) {
        this.resetError()
        this.clearFlights()
        this.clearMessages([])
        console.log("context cleared")
      }
      if (clearStatus) {
        this.resetGateUpdated()
        this.resetDepartureUpdated()
        this.resetDestinationUpdated()
        this.resetPublicStatusUpdated()
        this.setGateChange(false)
        this.resetGateChangeBar()
        console.log("status cleared")
      }
      if (!reconnectSocket) {
        this.autoReconnect = false
        console.log("auto-reconnect disabled")
        this.flightId = ""
      }
      if (clearSocket) {
        console.log("disconnecting socket")
        this.disconnect(forceReconnection)
      }
    })
    this.$root.$on("generatedCaptchaToken", (payload: any) => {
      const { token: newToken = "" } = payload
      this.token = newToken
    })
    this.$root.$on("expiredCaptchaToken", () => {
      this.token = ""
    })
    this.$root.$on("failedCaptchaToken", () => {
      this.failedCaptchaToken = true
    })
    this.$root.$on("appVisibilityChange", (payload: any) => {
      const { status } = payload
      if (status) {
        this.checkAndRemoveGateChange()
        
        if (this.isSocketConnected) {
          // send Keep-Alive message
          this.sendKeepAlive()
        } else if (this.autoReconnect && !this.autoReconnectInProgress) {
          // only reconnect when the browser tab is active
          setTimeout(() => {
            this.$root.$emit("disconnectWebSocket", {
              clearContext: false,
              clearStatus: false,
              clearSocket: true,
              reconnectSocket: true,
              forceReconnection: true,
            })
          }, 500)
        }
      } else {
        this.autoReconnectInProgress = false
      }
    })
  },

  computed: {
    ...mapState(useFlightStore, ["getMostRecentFlight", "isLoaded", "isError"]),
    ...mapState(useBackendStore, ["isAppVisible", "isSocketConnected"]),
    ...mapState(userContactsStore, ["getUserPhone", "getUserEmail"]),

    reCaptchaSiteKey() {
      return import.meta.env.VITE_RECAPTCHA_SITE_KEY
    },

    exhaustedRecoveryAttempts() {
      return this.currentRecoveryAttempt >= this.maxRecoveryAttempts
    },
  },

  beforeUnmount() {
    this.disconnect(false)
  },

  methods: {
    ...mapActions(useFlightStore, [
      "addFlight",
      "setError",
      "checkAndRemoveGateChange",
      "clearFlights",
      "resetError",
      "resetGateUpdated",
      "resetDepartureUpdated",
      "resetDestinationUpdated",
      "resetPublicStatusUpdated",
      "setGateChange",
      "resetGateChangeBar",
      "updateDirectLogoUrl",
      "resetDirectLogoUrl"
    ]),
    ...mapActions(useMessageStore, [
      "addMessage",
      "delMessageById",
      "delMessageByMessageText",
      "clearMessages",
    ]),
    ...mapActions(useBackendStore, ["setSocketConnected"]),

    async waitForClosedSocket() {
      let retryCount = 0
      const maxRetries = 10
      const retryInterval = 200
      do {
        if (
          !this.socket ||
          this.socket.readyState === WebSocket.CLOSED ||
          Object.keys(this.socket).length === 0 ||
          retryCount >= maxRetries
        ) {
          break
        }
        await sleep(retryInterval)
        retryCount++
      } while (true)
      const closeTime = (retryCount * retryInterval) / 1000
      if (retryCount >= maxRetries) {
        console.error(`previous socket NOT closed in ${closeTime} seconds`)
        return false
      }
      return true
    },

    async renewToken() {
      // disable token generation if the site key empty/not present
      if (!this.reCaptchaSiteKey) {
        return true
      }

      this.failedCaptchaToken = false
      this.$root.$emit("generateCaptchaToken")
      // wait for the token
      let retryCount = 0
      const maxRetries = 300
      const retryInterval = 200
      do {
        if (this.token || retryCount >= maxRetries || this.failedCaptchaToken) {
          break
        }
        await sleep(retryInterval)
        retryCount++
      } while (true)
      const closeTime = (retryCount * retryInterval) / 1000
      if (retryCount >= maxRetries || this.failedCaptchaToken) {
        console.error(`new captcha token not ready in ${closeTime} seconds`)
        this.$root.$emit("resetCaptcha")
        return false
      }

      console.log(
        `${new Date().toString()} - renewed captcha token in ${closeTime} seconds, retried ${retryCount} times`
      )
      return true
    },

    sendKeepAlive() {
      const keepAlive = {
        action: "keepalive",
        TypeOfMsg: "KEEP_ALIVE",
        TimeStamp: new Date().toISOString(),
      }
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify(keepAlive))
      }
    },

    async connect() {
      if (!this.flightId) {
        console.log("connection attempt stopped - empty flight id")
        return
      }

      const tokenStatus = await this.renewToken()
      if (!tokenStatus) {
        if (
          this.autoReconnectInProgress &&
          this.isAppVisible &&
          !this.exhaustedRecoveryAttempts
        ) {
          setTimeout(() => {
            this.connect()
          }, this.socketReconnectTimeout)
        } else {
          this.setError()
        }
        return
      }

      // reset to default
      this.autoReconnect = true

      let socketUrl = `${import.meta.env.VITE_APP_WS}`
      if (this.gate) {
        socketUrl += `gate=${this.gate}`
      } else {
        socketUrl += `flightID=${this.flightId}`
      }
      socketUrl += `&token=${this.token}`
      console.log(
        `web socket connect to ${socketUrl}, attempt ${
          this.currentRecoveryAttempt + 1
        } of ${this.maxRecoveryAttempts + 1}`
      )

      // encode phone/email with base64 algorithm
      socketUrl += `&p=${btoa(this.getUserPhone)}`
      socketUrl += `&m=${btoa(this.getUserEmail)}`
      socketUrl += `&l=${this.$i18n.locale}`

      this.socket = new WebSocket(socketUrl)
      // set socket event handlers
      this.socket.onopen = this.onOpen
      this.socket.onmessage = this.onMessage
      this.socket.onclose = this.onClose
      this.socket.onerror = this.onError

      // reset captcha component to force expiry of existing token
      // can not generate new tokens without that
      this.$root.$emit("resetCaptcha")

      // clear previous value
      clearTimeout(this.errorTimeoutId)
      this.errorTimeoutId = setTimeout(() => {
        if (!this.isLoaded) {
          clearTimeout(this.errorTimeoutId)
          this.setError()
        }
      }, this.requestTimeout)
    },

    onOpen() {
      console.log("web socket connected")

      this.currentRecoveryAttempt = 0
      this.setSocketConnected(true)
      // clear error timeout id to avoid setting error condition
      clearTimeout(this.errorTimeoutId)
      this.autoReconnectInProgress = false

      // reset gate to prevent using it at autoreconnecting
      this.gate = ""

      this.keepAliveId = setInterval(() => {
        this.sendKeepAlive()
      }, this.keepAliveInterval)
    },

    onMessage(message: any) {
      const { data } = message
      const messageData = JSON.parse(data)
      console.log("web socket message:", messageData)

      const { MessageType } = messageData

      if (MessageType === FIDS_MESSAGE) {
        const { codeshare } = messageData
        let codeshareCopy = []
        if (codeshare) {
          codeshareCopy = JSON.parse(codeshare)
        }
        const { stops } = messageData
        let stopsCopy = []
        if (stops) {
          stopsCopy = JSON.parse(stops)
        }
        let messageDataCopy = {
          ...messageData,
          codeshare: codeshareCopy,
          stops: stopsCopy,
        }

        const { flightid } = messageData
        // save flight id for auto-reconnections
        this.flightId = flightid

        // flight updates
        if (this.isLoaded) {
          // save to flights store
          const localId = this.addFlight({
            ...messageDataCopy,
          })
          console.log(`Flight added with local ID: ${localId}`)
        } else {
          // send data to Progress Dialog and Options page
          this.$root.$emit("initialFlightReceived", {
            ...messageDataCopy,
          })
        }
      } else {
        if (MessageType === MESSAGE_TYPE_60 || MessageType === MESSAGE_TYPE_70) {
          const { EventType } = messageData
          if (EventType === EVENT_TYPE_SPECIAL_FLIGHT) {
            const { action, flightID, logoURL, cityURL, endDate, startDate } = messageData
            if (action === "Display") {
              let Time = new Date().toLocaleString("en-US", { timeZone: "america/new_york" })
              let currentTime = new Date(Time)
              this.resetDirectLogoUrl()
              let startDateTime = new Date(startDate)
              let startDateDiff = startDateTime - currentTime
              setTimeout(() => {
                this.updateDirectLogoUrl({
                flightID,
                logoUrl: logoURL,
                cityURL: cityURL,
                endDate: endDate,
                startDate: startDate,
                showUrl: true
              })
              }, startDateDiff);
              if(endDate){
              let endDateTime = new Date(endDate)
              let endDateDiff = endDateTime - currentTime
              setTimeout(() => {
                this.resetDirectLogoUrl()
              }, endDateDiff);
              }
            }
          } else {
            const { SubType } = messageData
            if (
              [
                BOARD_GATE_STATUS_MESSAGE,
                CANNED_MESSAGE,
                CUSTOM_MESSAGE,
              ].includes(SubType)
            ) {
              this.addMessage({ ...messageData })
            } else {
              console.error(`Messages of SubType ${SubType} not handled`)
            }
          }
        } else if (MessageType !== "99") {
          const { Action } = messageData
          if (Action && Action === ACTION_REMOVE) {
            const { id, MessageText } = messageData
            if (id) {
              if (!this.delMessageById(id)) {
                this.delMessageByMessageText(MessageText)
              }
            } else {
              this.delMessageByMessageText(MessageText)
            }
          } else {
            this.addMessage({ ...messageData })
          }
        }
      }
    },

    onClose(event: any) {
      const { code, reason, wasClean } = event
      console.log(
        `web socket closed: code - ${code}, reason - ${reason}, wasClean - ${wasClean}`
      )

      this.setSocketConnected(false)

      clearInterval(this.keepAliveId)
      console.log(
        `web socket Keep-Alive interval id ${this.keepAliveId} cleared`
      )
      this.keepAliveId = 0
      if (this.isLoaded && this.autoReconnect && this.isAppVisible) {
        // remove IROPS, VP and Emergency before auto-reconnecting
        // leave PA - 4 and 5
        console.log("removing some messages")
        this.clearMessages([1, 2, 3])
        console.log("reconnecting web socket")
        setTimeout(() => {
          this.autoReconnectInProgress = true
          this.connect()
        }, this.socketReconnectTimeout)
      }
    },

    onError(e: any) {
      console.log("web socket error", e)
      if (!this.isError && this.isAppVisible) {
        this.currentRecoveryAttempt++
        if (this.currentRecoveryAttempt <= this.maxRecoveryAttempts) {
          // use exp growth to calc delay before next attempt
          const growthRate = 1
          const delay =
            this.initialRecoveryDelay *
            Math.pow(1 + growthRate, this.currentRecoveryAttempt)
          console.log(`repeat in ${delay} ms`)
          setTimeout(() => {
            this.connect()
          }, delay)
        } else {
          this.setError()
          this.autoReconnect = false
          this.autoReconnectInProgress = false
        }
      }
    },

    disconnect(forceReconnection: boolean) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.close()
      }
      if (forceReconnection === true) {
        setTimeout(() => {
          this.connect()
        }, 500)
      }
    },
  },
}
</script>

