{
	"captcha": {
		"statement": "protected by reCAPTCHA",
		"privacy": "Privacy",
		"terms": "Terms"
	},

	"confirmations": {
		"title": "System Update",
		"cancellation": "Thank you for using the Toronto Pearson Web App. This flight has been cancelled and there will be no more updates. Please close this browser window.",
		"departure": "Thank you for using the Toronto Pearson Web App. This flight has been departed and there will be no more updates. Please close this browser window.",

		"buttons": {
			"cancel": "Cancel",
			"close": "Close",
			"try_again": "Try again"
		}
	},

	"locales": {
		"english": "English",
		"french": "Français"
	},

	"nav": {
		"home": "Home",
		"flight_info": "Your Flight",
		"messages": "Messages",

		"status_or_gate_updated": "Flight details updated",
		"unread_messages": "Number of unread messages: {messages}"
	},

	"flight_info": {
		"page_title": "Mobile Gate Device - Your Flight",
		"page_description": "The GTAA App provides passengers with the latest flight information updates and messages for selected flights. On the Your Flight Page, passengers can see the gate, latest time of departure and status of their flight.",

		"title": "Flight Info Page",

		"airline_logo_alt": "{airlinename} logo",

		"alerts": {
			"departure_updated": "Departure time is updated",
			"destination_updated": "Flight destination is updated",
			"public_status_updated": "Flight status is updated",
			"gate_updated": "Gate is updated"
		},

		"flight_status": {
			"boarding": "Boarding",
			"CAN": "Cancelled",
			"DEL": "Delayed",
			"DEP": "Departed",
			"ERL": "Early",
			"final_call": "Final Call",
			"gate_closed": "Gate Closed",
			"gate_open": "Gate Open",
			"ONT": "On Time",
			"NOP": "Non Operational"
		},

		"destination": {
			"title": "Destination",
			"stops": "Stops"
		},

		"departure_time": "Departing",

		"flight_number": {
			"title": "Flight Number",
			"code_share": "Code Share"
		},
		
		"flight_status_header": "Flight Status",

		"gate": "Gate",

		"gate_change": "GATE CHANGE ALERT: Flight {airlineCode} {flightNumber} to {destination} now departing at gate {newGate}.",

		"gate_message_header": "Gate Message",

		"error_flight_not_loaded": "Loading flight"
	},

	"messages": {
		"page_title": "Mobile Gate Device - Messages",
		"page_description": "The GTAA App provides passengers with the latest flight information updates and messages for selected flights. On the Messages Page, passengers can view the latest messages about their flight.",

		"title": "Messages and Updates",

		"empty_content": "There have not been any messages received yet.",

		"month": {
			"0": "Jan",
			"1": "Feb",
			"2": "Mar",
			"3": "Apr",
			"4": "May",
			"5": "Jun",
			"6": "Jul",
			"7": "Aug",
			"8": "Sep",
			"9": "Oct",
			"10": "Nov",
			"11": "Dec"
		}
	},

	"options": {
		"page_title": "Mobile Gate Device - Home",
		"page_description": "The GTAA App provides passengers with the latest flight information updates and messages for flights. On the Home Page, passengers can input their flight number to find the current information on their flights.",

		"title": "Home",

		"logo_alt_text": "Toronto Pearson GTAA logo",

		"track_flight": {
			"label": "Enter a flight number, e.g. EE123, EE090 or EE009",
			"button": "Find",

			"instr": "How would you like to be further notified?",

			"notify_options": {
				"none": "None",
				"sms": "Mobile SMS",
				"email": "E-mail",
				"standard_disclaimer": "Message and data rates may apply"
			},

			"dialog_label": "Finding flight information",
			"track_finished_label": "Received",

			"error": {
				"not_found": "The flight number entered is incorrect or the flight number is not found for the next 12 hours. Please check and enter the correct flight number or check the flight date.",
				"cancelled": "This flight has been cancelled. Please double-check the flight number or try a different flight number.",
				"departed": "This flight has departed. Please double-check the flight number or try a different flight number.",
				"contact_phone_not_provided": "Please provide your mobile phone number.",
				"contact_phone_not_valid": "This mobile phone number is not valid.",
				"contact_email_not_provided": "Please provide your e-mail address.",
				"contact_email_not_valid": "This email address is not valid."
			}
		}
	}
}
