{
	"captcha": {
		"statement": "protégé par reCAPTCHA",
		"privacy": "Confidentialité",
		"terms": "Modalités"
	},

	"confirmations": {
		"title": "Mise à jour du système",
		"cancellation": "Merci d’utiliser l’application Web de l’aéroport Toronto Pearson. Ce vol a été annulé et il n’y aura plus de mises à jour. Veuillez fermer cette fenêtre du navigateur.",
		"departure": "Merci d’utiliser l’application Web de l’aéroport Toronto Pearson. Le vol a décollé et il n’y aura plus de mises à jour. Veuillez fermer cette fenêtre du navigateur.",

		"buttons": {
			"close": "Fermer",
			"cancel": "Annuler",
			"try_again": "Réessayer"
		}
	},

	"locales": {
		"english": "English",
		"french": "Français"
	},

	"nav": {
		"home": "Accueil",
		"flight_info": "Votre vol",
		"messages": "Messages",

		"status_or_gate_updated": "Détails du vol mis à jour",
		"unread_messages": "Nombre de messages non lus: {messages}"
	},

	"flight_info": {
		"page_title": "Dispositif de portail mobile - Ton vol",
		"page_description": "L'application GTAA fournit aux passagers les dernières mises à jour d'informations sur les vols et les messages pour les vols sélectionnés. Sur la page Votre vol, les passagers peuvent voir la porte d'embarquement, la dernière heure de départ et le statut de leur vol.",

		"title": "Flight Info Page",

		"airline_logo_alt": "{airlinename} logo",

		"alerts": {
			"departure_updated": "L'heure de départ est mise à jour",
			"destination_updated": "La destination du vol est mise à jour",
			"public_status_updated": "Le statut du vol est mis à jour",
			"gate_updated": "La porte est mise à jour"
		},

		"flight_status": {
			"boarding": "Embarquement",
			"CAN": "Annulé",
			"DEL": "En retard",
			"DEP": "Parti",
			"ERL": "Tôt",
			"final_call": "Dernier appel",
			"gate_closed": "Porte fermèe",
			"gate_open": "Porte ouverte",
			"ONT": "À l’heure"
		},

		"destination": {
			"title": "Destination",
			"stops": "Arrêts"
		},

		"departure_time": "Départ",

		"flight_number": {
			"title": "Numéro de vol",
			"code_share": "Partage de code"
		},

		"flight_status_header": "L'état du vol",

		"gate": "Porte",

		"gate_change": "CHANGEMENT DE PORTE: Le Vol {airlineCode} {flightNumber} à destination {destination} départ de la porte {newGate}.",

		"gate_message_header": "Message de porte",

		"error_flight_not_loaded": "Embarquement pour le vol"
	},

	"messages": {
		"page_title": "Dispositif de portail mobile - messages",
		"page_description": "L'application GTAA fournit aux passagers les dernières mises à jour d'informations sur les vols et les messages pour les vols sélectionnés. Sur la page Messages, les passagers peuvent consulter les derniers messages concernant leur vol.",

		"title": "Messages et mises à jour",

		"empty_content": "Aucun message n'a encore été reçu.",

		"month": {
			"0": "janv.",
			"1": "févr.",
			"2": "mars",
			"3": "avr.",
			"4": "mai",
			"5": "juin",
			"6": "juil.",
			"7": "août",
			"8": "sept.",
			"9": "oct.",
			"10": "nov.",
			"11": "déc."
		}
	},

	"options": {
		"page_title": "Dispositif de portail mobile - Accueil",
		"page_description": "L'application GTAA fournit aux passagers les dernières mises à jour d'informations sur les vols et les messages pour les vols. Sur la page d'accueil, les passagers peuvent entrer leur numéro de vol pour trouver les informations actuelles sur leurs vols.",

		"title": "Choix",

		"logo_alt_text": "Logo Toronto Pearson GTAA",

		"track_flight": {
			"label": "Entrer un numéro de vol, p. ex. EE123, EE090 ou EE009",
			"button": "Rechercher",

			"instr": "Comment souhaitez-vous être informé ?",

			"notify_options": {
				"none": "Aucun",
				"sms": "SMS cellulaire",
				"email": "Courriel",
				"standard_disclaimer": "Des messages et débits de données peuvent être appliqués"
			},

			"dialog_label": "Recherche d’information de vols",
			"track_finished_label": "Reçu",

			"error": {
				"not_found": "Le numéro de vol entré est inexact ou le numéro de vol est introuvable pour les 12 prochaines heures. Veuillez vérifier et entrer le numéro de vol exact ou vérifier la date du vol.",
				"cancelled": "Ce vol a été annulé. Veuillez vérifier de nouveau le numéro de vol ou en essayer un autre.",
				"departed": "Ce vol a décollé. Veuillez vérifier de nouveau le numéro de vol ou en essayer un autre.",
				"contact_phone_not_provided": "Veuillez fournir votre numéro de téléphone.",
				"contact_phone_not_valid": "Ce numéro cellulaire n’est pas valide.",
				"contact_email_not_provided": "Veuillez fournir votre courriel.",
				"contact_email_not_valid": "Ce courriel électronique n’est pas valide."
			}
		}
	}
}
