<script setup lang="ts">
import { defineComponent } from "vue"
import { mapState, mapActions } from "pinia"
import { useI18n } from "vue-i18n-bridge"
import type { Flight } from "../stores/flight"
import { useFlightStore } from "../stores/flight"
import { userContactsStore } from "../stores/userContacts"

import { sleep } from "../utils/api"

import { isNavigationFailure, NavigationFailureType } from "vue-router"

defineProps<{
  gate?: string
  p?: string
  m?: string
  l?: string
  f?: string
}>()

const { t } = useI18n()
</script>

<template>
  <div class="Options">
    <LocaleSwitcher />
    <ScreenOverlay v-show="tracking" />

    <div class="Options-Top">
      <CorpLogo :alt="t('options.logo_alt_text')" />
    </div>

    <div class="Options-Bottom">
      <div class="Options-TrackFlight">
        <div v-show="progressContent" class="Tracking-Progress body-2">
          {{ t(`${progressContent}`) }}
        </div>
        <v-alert v-show="errorContent" text color="#E71A1C" type="error">
          {{ t(`${errorContent}`) }}
        </v-alert>
        <TrackFlight
          :loading="tracking"
          @track="onTrackFlight"
          @reset="onResetAlerts"
        />
      </div>
      <CaptchaBadge />
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  data: () => ({
    tracking: false,

    progressContent: "",
    errorContent: "",
  }),

  metaInfo: (app) => ({
    title: app.$t("options.page_title"),
    meta: [
      {
        name: "description",
        content: app.$t("options.page_description"),
      },
    ],
  }),

  created() {
    this.$root.$on("initialFlightReceived", (payload: Flight) => {
      this.tracking = false

      const { publicstatus = "" } = payload
      if (publicstatus === "DEP") {
        this.progressContent = ""
        this.errorContent = "options.track_flight.error.departed"
        this.$root.$emit("disconnectWebSocket", {})
      } else if (publicstatus === "CAN") {
        this.progressContent = ""
        this.errorContent = "options.track_flight.error.cancelled"
        this.$root.$emit("disconnectWebSocket", {})
      } else {
        this.progressContent = "options.track_flight.track_finished_label"
        this.addFlight(payload)
        setTimeout(() => {
          this.$router.replace({ name: "flight" }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated))
              throw error
          })
        }, 1000)
      }
    })

    const { f, m, p, l } = this
    if (f) {
      this.setUserFlight(f)
    }
    if (p) {
      // phone
      this.setUserPhone(atob(p.trim()))
    }
    if (m && !p) {
      // email
      this.setUserEmail(atob(m.trim()))
    }
    if (l) {
      // locale
      this.$root.$i18n.locale = l.trim()
    }
  },

  mounted() {
    const { gate } = this
    if (gate) {
      const GATE_MAXLEN = 8
      if (gate.length <= GATE_MAXLEN) {
        setTimeout(() => {
          this.onTrackFlight({
            gate,
          })
        }, 100)
      }
    }
  },

  computed: {
    ...mapState(useFlightStore, ["isError"]),
  },

  methods: {
    ...mapActions(useFlightStore, ["addFlight"]),
    ...mapActions(userContactsStore, [
      "setUserFlight",
      "setUserPhone",
      "setUserEmail",
    ]),

    async onTrackFlight(o: any) {
      this.tracking = true
      this.progressContent = "options.track_flight.dialog_label"
      this.errorContent = ""

      this.$root.$emit("disconnectWebSocket", {})
      await sleep(500)
      this.$root.$emit("connectWebSocket", o)
    },

    onResetAlerts() {
      this.errorContent = ""
    },
  },

  watch: {
    isError: {
      handler(newValue) {
        if (newValue === true) {
          this.errorContent = "options.track_flight.error.not_found"
          this.progressContent = ""
          this.tracking = false
        }
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.Options {
  @media all and (max-height: 450px) {
    overflow-y: auto;
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Options-Top {
  @media all and (max-width: 600px) {
    min-height: 30%;
  }
  @media all and (max-height: 450px) {
    min-height: 25%;
  }
  margin-top: -21px;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Options-Bottom {
  @media all and (max-width: 600px) {
    min-height: 70%;
  }
  @media all and (max-height: 450px) {
    min-height: 75%;
  }
  min-height: 60%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Options-TrackFlight {
  @media all and (min-width: 1281px) and (max-width: 1920px) {
    min-width: 50rem;
    max-width: 50rem;
  }
  @media all and (min-width: 1921px) {
    min-width: 70rem;
    max-width: 70rem;
  }
  min-width: 90%;
  max-width: 90%;
}

.Tracking-Progress {
  color: #5f6a72;
  text-align: center;
  margin: 0.5rem 0;
}
.Tracking-Error {
  color: #e71a1c;
}
</style>
