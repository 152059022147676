<script setup lang="ts">
import { defineComponent } from "vue"

import { VueRecaptcha } from "vue-recaptcha"
</script>

<template>
  <vue-recaptcha
    :language="reCaptchaLanguage" 
    v-show="false"     
    ref="recaptcha"
    :sitekey="reCaptchaSiteKey"
    :loadRecaptchaScript="true"
    size="invisible"
    badge="inline"
    @verify="onCaptchaVerify"
    @expired="onCaptchaExpired"
    @render="onCaptchaRendered"
    @error="onError"
    ></vue-recaptcha>
</template>

<script lang="ts">
export default defineComponent({
  name: "SharedCaptcha",

  components: { VueRecaptcha },

  data: () => ({
    captchaId: null,
  }),

  computed: {
    reCaptchaSiteKey() {
      return `${import.meta.env.VITE_RECAPTCHA_SITE_KEY}`
    },
    reCaptchaLanguage() {
      let locale = localStorage.getItem("locale")
      if(locale){
        this.$root.$i18n.locale = locale;
        return locale
      }else{
        return 'en'
      }
    },
  },

  mounted() {
    this.$root.$on("generateCaptchaToken", () => {
      this.$refs.recaptcha.execute()
    })
    this.$root.$on("resetCaptcha", () => {
      console.log("resetting captcha component")
      this.$refs.recaptcha.reset()
      // for a reason onCaptchaExpired not fired after reset()
      this.$root.$emit("expiredCaptchaToken")
    })
  },

  methods: {
    onCaptchaVerify(token: string) {
      console.log("captcha token generated")
      this.$root.$emit("generatedCaptchaToken", { token })
    },
    onCaptchaExpired() {
      console.log("captcha token expired")
      this.$root.$emit("expiredCaptchaToken")
    },
    onCaptchaRendered(id: any) {
      console.log(`captcha rendered with id ${id}`)
      this.captchaId = id
    },
    onError() {
      console.log("error working with captcha")
      this.$root.$emit("failedCaptchaToken")
    },
  },
  // watch this.$root.$i18n.locale
  watch: {
    "$root.$i18n.locale": async function (newValue) {
      let localeFromLocalStorage = localStorage.getItem('locale');
      if(localeFromLocalStorage !=newValue ){

      localStorage.setItem('locale', newValue)
      // refresh page
      this.$router.go()	
      }
    },
  },
})
</script>

<style lang="scss">
@media screen and (orientation: landscape) and (max-height: 600px) {
  body > div:nth-child(5) > div:nth-child(2) {
    transform: scale(0.45) !important;
    -webkit-transform: scale(0.45) !important;
    transform-origin: 0 0 !important;
    -webkit-transform-origin: 0 0 !important;
    position: relative !important
    ;
  }
}
@media screen and (orientation: portrait) {
  body > div:nth-child(5) > div:nth-child(2) {
    right: unset !important;
  }
}
</style>
