<script setup lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n-bridge"

const { t } = useI18n()
</script>

<template>
  <div class="LocaleSwitcher rounded mgd-lang">
    <button
      v-bind:class="[{ 'LocaleSwitcher-CurrentLocale': isCurrentLocale('en') }, langSelector, hoverEffect]"
      @click="switchLocale('en')"
    >
      {{ t("locales.english") }}
    </button>
    <div class="mgd-lang-spacer">/</div>
    <button
      v-bind:class="[{ 'LocaleSwitcher-CurrentLocale': isCurrentLocale('fr') }, langSelector, hoverEffect]"
      @click="switchLocale('fr')"
    >
      {{ t("locales.french") }}
    </button>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  data: () => ({
    langSelector: "mgd-lang-selector",
    hoverEffect: "hvr-underline-from-left",
  }),

  methods: {
    isCurrentLocale(locale: string) {
      return this.$root.$i18n.locale === locale
    },
    switchLocale(locale: string) {
      this.$root.$i18n.locale = locale
    },
  },
})
</script>

<style scoped>
.LocaleSwitcher {
  position: relative;
  /* left: calc(100% - 10.2rem); */
  top: 1rem;
  background-color: white;
  padding: 0 1rem;
  /* z-index: 777; */
  margin-right: 0;
  margin-left: auto;
}
.LocaleSwitcher-CurrentLocale {
  font-weight: 600;
}
</style>

