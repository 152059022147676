<script setup lang="ts">
import { defineComponent } from "vue"
import { mapActions } from "pinia"
import { useBackendStore } from "../stores/backend"
</script>

<template>
  <div v-visibility-change="visibilityChange"></div>
</template>

<script lang="ts">
export default defineComponent({
  name: "AppVisibility",

  methods: {
    ...mapActions(useBackendStore, ["setAppVisibilityStatus"]),

    visibilityChange(evt: any, hidden: boolean) {
      console.log(`app active: ${!hidden}`)
      const o = {
        status: !hidden,
        localTimeStamp: new Date()
      }
      this.setAppVisibilityStatus(o)
      this.$root.$emit("appVisibilityChange", o)
    },
  },
})
</script>
