<script setup lang="ts">
import { useI18n } from "vue-i18n-bridge"
import { defineComponent } from "vue"

import { mapState, mapActions } from "pinia"
import { userContactsStore } from "../stores/userContacts"

const { t } = useI18n()

defineProps<{
  loading?: boolean
}>()
</script>

<template>
  <div class="TrackFlight">
    <v-alert v-model="showAlert" text color="#E71A1C" type="error">
      {{ $t(`${errorContent}`) }}
    </v-alert>

    <div class="TrackFlight-Container-FlightId">
      <v-text-field
        flat
        outlined
        clearable
        hide-details
        :label="t(`options.track_flight.label`)"
        :value="getUserFlight"
        prepend-inner-icon="$mdi-airplane-search"
        maxlength="8"
        :loading="loading"
        :dense="$vuetify.breakpoint.smOnly"
        class="TrackFlight-FlightId"
        @keyup.enter.native="onEnter"
        @input="setUserFlight"
        @click:clear="onClear"
      ></v-text-field>
      <v-btn
        color="primary"
        :x-large="!$vuetify.breakpoint.smOnly"
        :disabled="!getUserFlight || getUserFlight.length <= 0"
        class="ml-4 d-none d-sm-flex"
        :style="trackButtonCssProps"
        @click="onEnter"
      >
        {{ t(`options.track_flight.button`) }}
      </v-btn>
    </div>

    <div v-show="getUserFlight" class="TrackFlight-ContactsArea">
      <div style="display: flex; flex-direction: row">
        <div class="TrackFlight-ContactsArea-Instr">
          {{ t(`options.track_flight.instr`) }}
        </div>
      </div>
      <div class="TrackFlight-ContactsArea-Container-Fields">
        <v-radio-group
          v-model="notifyOption"
          row
          :dense="$vuetify.breakpoint.smOnly"
          class="mr-sm-2"
        >
          <v-radio
            :label="$t('options.track_flight.notify_options.none')"
            value="none"
          ></v-radio>
          <v-radio
            :label="$t('options.track_flight.notify_options.sms')"
            value="sms"
          ></v-radio>
          <v-radio
            :label="$t('options.track_flight.notify_options.email')"
            value="email"
          ></v-radio>
        </v-radio-group>
        <div class="TrackFlight-ContactsArea-Fields">
          <v-text-field
            v-show="notifyOption === 'sms'"
            prefix="+1"
            :rules="[rules.required]"
            flat
            outlined
            clearable
            hide-details
            :value="getUserPhone"
            prepend-inner-icon="$mdi-phone"
            maxlength="10"
            type="tel"
            :dense="$vuetify.breakpoint.smOnly"
            class="TrackFlight-ContactsArea-Phone"
            @keyup.enter.native="onEnter"
            @keydown="onTypePhone"
            @input="setUserPhone"
          ></v-text-field>
          <v-text-field
            v-show="notifyOption === 'email'"
            :rules="[rules.required]"
            flat
            outlined
            clearable
            hide-details
            :value="getUserEmail"
            prepend-inner-icon="$mdi-email"
            maxlength="128"
            type="email"
            :dense="$vuetify.breakpoint.smOnly"
            class="TrackFlight-ContactsArea-Email"
            @keyup.enter.native="onEnter"
            @input="setUserEmail"
          ></v-text-field>
          <div v-show="notifyOption === 'sms'" class="disclaimer">{{ t(`options.track_flight.notify_options.standard_disclaimer`) }}</div>
        </div>
      </div>
    </div>

    <div class="TrackFlight-Controls d-sm-none">
      <v-btn
        color="primary"
        :x-large="!$vuetify.breakpoint.smOnly"
        :disabled="!getUserFlight || getUserFlight.length <= 0"
        :style="trackButtonCssProps"
        @click="onEnter"
      >
        {{ t(`options.track_flight.button`) }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  data: () => ({
    notifyOption: "",

    rules: {
      required: (value: any) => !!value || "Required field.",
    },
    errorContent: "",
    showAlert: false,
  }),

  created() {
    if (this.getUserPhone) {
      this.notifyOption = "sms"
    }
    if (this.getUserEmail && !this.getUserPhone) {
      this.notifyOption = "email"
    }
  },

  computed: {
    ...mapState(userContactsStore, [
      "getUserFlight",
      "getUserPhone",
      "getUserEmail",
    ]),

    trackButtonCssProps() {
      let minWidth: string = `10rem`
      if (["sm"].includes(this.$vuetify.breakpoint.name)) {
        minWidth = "12rem"
      } else if (["md"].includes(this.$vuetify.breakpoint.name)) {
        minWidth = "14rem"
      } else if (["lg", "xl"].includes(this.$vuetify.breakpoint.name)) {
        minWidth = "16rem"
      }

      const maxWidth: string = minWidth
      return {
        minWidth,
        maxWidth,
      }
    },
  },

  methods: {
    ...mapActions(userContactsStore, [
      "setUserFlight",
      "setUserPhone",
      "setUserEmail",
    ]),

    onClear() {
      this.setUserFlight("")
    },

    onEnter() {
      const { loading = false, notifyOption } = this
      if (!loading) {
        const EMAIL_VALIDATION_RULES =
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const PHONE_VALIDATION_RULES = /^\d{10}$/

        if (notifyOption === "sms" && !this.getUserPhone) {
          this.$emit("reset")
          this.errorContent =
            "options.track_flight.error.contact_phone_not_provided"
          this.showAlert = true
        } else if (
          notifyOption === "sms" &&
          !this.getUserPhone.match(PHONE_VALIDATION_RULES)
        ) {
          this.$emit("reset")
          this.errorContent =
            "options.track_flight.error.contact_phone_not_valid"
          this.showAlert = true
        } else if (notifyOption === "email" && !this.getUserEmail) {
          this.$emit("reset")
          this.errorContent =
            "options.track_flight.error.contact_email_not_provided"
          this.showAlert = true
        } else if (
          notifyOption === "email" &&
          !this.getUserEmail.match(EMAIL_VALIDATION_RULES)
        ) {
          this.$emit("reset")
          this.errorContent =
            "options.track_flight.error.contact_email_not_valid"
          this.showAlert = true
        } else {
          this.showAlert = false
          const flightId = this.getUserFlight
          this.$emit("track", { flightId })
        }
      }
    },
    onInput() {
      this.$emit("reset")
    },
    onTypePhone(e: any) {
      const PHONE_MAX_LEN: number = 10
      if (
        ![
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "Meta",
          "Control",
          "Alt",
          "Shift",
          "ArrowLeft",
          "ArrowRight",
          "ArrowUp",
          "ArrowDown",
          "Delete",
          "Backspace",
          "Tab",
        ].includes(e.key) ||
        this.getUserPhone?.length > PHONE_MAX_LEN
      ) {
        e.preventDefault()
      }
    },
  },

  watch: {
    notifyOption: {
      handler(newValue) {
        if (newValue === "none") {
          this.setUserPhone("")
          this.setUserEmail("")
          this.showAlert = false
        } else if (newValue === "sms") {
          this.showAlert = false
          this.setUserEmail("")
        } else if (newValue === "email") {
          this.showAlert = false
          this.setUserPhone("")
        }
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.TrackFlight {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: top;
  flex-grow: 1;
  flex-shrink: 1;
}
.TrackFlight-Container-FlightId {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.disclaimer{
  margin-top: 10px;
  font-size: 0.9rem;
  font-style: italic;
  color: grey;
  min-width: 3rem;
}
.TrackFlight-FlightId {
  @media all and (max-width: 600px) {
    min-width: 100%;
  }
}
.TrackFlight-ContactsArea {
  @media all and (max-width: 600px) {
    margin: 2rem 0;
  }
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}
.TrackFlight-ContactsArea-Instr {
  font-size: 0.9rem;
  font-style: italic;
  color: grey;
  min-width: 3rem;
}
.TrackFlight-ContactsArea-Instr-Or {
  text-align: center;
  margin: 0 0.5rem;
}
.TrackFlight-ContactsArea-Container-Fields {
  @media all and (max-height: 450px) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
}
.TrackFlight-ContactsArea-Fields {
  display: block;
  flex: 1;
}
.TrackFlight-ContactsArea-Phone {
  @media all and (min-width: 600px) {
    max-width: 14rem;
  }
  max-width: inherit;
}
.TrackFlight-ContactsArea-Email {
  @media all and (max-height: 450px) {
    max-width: 80%;
  }
}
.TrackFlight-Controls {
  @media all and (min-width: 600px) {
    margin-left: 1rem;
    margin-top: 0;
  }
  margin-left: 0;
  margin-top: 1rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}
</style>

