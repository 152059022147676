<script setup lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n-bridge"
import { mapState } from "pinia"
import { useFlightStore } from "../stores/flight"

const { t } = useI18n()
</script>

<template>
  <div>
    <v-alert v-if="isDepartureUpdated" type="warning" tile class="hide-element">
      {{ t("flight_info.alerts.departure_updated") }}
    </v-alert>
    <v-alert v-if="isDestinationUpdated" type="warning" tile class="hide-element">
      {{ t("flight_info.alerts.destination_updated") }}
    </v-alert>
    <v-alert v-if="isPublicStatusUpdated" type="warning" tile class="hide-element">
      {{ t("flight_info.alerts.public_status_updated") }}
    </v-alert>
    <v-alert v-if="isGateUpdated" type="warning" tile class="hide-element">
      {{ t("flight_info.alerts.gate_updated") }}
    </v-alert>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "FlightUpdateAlert",

  computed: {
    ...mapState(useFlightStore, [
      "isDepartureUpdated",
      "isDestinationUpdated",
      "isPublicStatusUpdated",
      "isGateUpdated",
    ]),
  },
})
</script>

