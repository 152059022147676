<script setup lang="ts">
import { useI18n } from "vue-i18n-bridge"
import { defineComponent } from "vue"

const { t } = useI18n()
</script>

<template>
  <v-dialog v-model="show" max-width="290">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="agree">
          {{ $t('confirmations.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
export default defineComponent({
  name: 'NotificationDialog',

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: "",
    title: ""
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value: boolean) {
        this.dialog = value
        if (value === false) {
          this.disagree()
        }
      }
    }
  },
  methods: {
    open(title: string, message: string) {
      this.dialog = true
      this.title = title
      this.message = message
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    disagree() {
      this.resolve(false)
      this.dialog = false
    },
  }
})
</script>
