<script setup lang="ts">
import { defineComponent } from "vue"

import { mapState } from "pinia"
import { useRollOffStore } from "../stores/rollOff"

import { useI18n } from "vue-i18n-bridge"

const { t } = useI18n()
</script>

<template>
  <NotificationDialog ref="notificationDialog" />
</template>

<script lang="ts">
export default defineComponent({
  name: "WatchRollOff",

  computed: {
    ...mapState(useRollOffStore, ["isRolledOff"]),
  },

  watch: {
    isRolledOff: {
      async handler(newValue) {
        const disconnectParams = {
          clearContext: false,
          clearStatus: true,
          clearSocket: true,
          reconnectSocket: false
        }
        if (newValue === true) {
          setTimeout(() => {
            this.$root.$emit("disconnectWebSocket", disconnectParams)
          }, 500)
          // dialog's title is intentionally blank
          // show same message as for departed
          await this.$refs.notificationDialog.open(
            "",
            this.$t("confirmations.departure")
          )
        }
      },
    },
  },
})
</script>

