<script setup lang="ts">
import { defineComponent } from "vue"

defineProps<{
  minWidth?: string
  minHeight?: string,
  alt?: string
}>()
</script>

<template>
  <div class="CorpLogo" :style="styleProps">
    <svg
      role="img" :aria-label="alt"
      viewBox="0 0 211 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 52.3 (67297) - http://www.bohemiancoding.com/sketch -->
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Header---Tablet" transform="translate(-32.000000, -19.000000)">
          <g id="Group-3" transform="translate(32.000000, 19.000000)">
            <g
              id="Group"
              transform="translate(0.000000, 10.000000)"
              fill="#002663"
            >
              <path
                d="M9.36256703,9.85747961 C9.36256703,6.68158376 11.4342484,4.29640597 14.5617709,4.29640597 C17.669758,4.29640597 19.7405091,6.68158376 19.7405091,9.85747961 C19.7405091,13.0305847 17.669758,15.4399492 14.5617709,15.4399492 C11.4556443,15.4399492 9.36256703,13.0305847 9.36256703,9.85747961 M17.3330051,9.85747961 C17.3330051,7.74021571 16.3413517,6.20994369 14.5617709,6.20994369 C12.7849809,6.20994369 11.7728618,7.74021571 11.7728618,9.85747961 C11.7728618,11.9952092 12.7849809,13.5264114 14.5617709,13.5264114 C16.3413517,13.5264114 17.3330051,11.9952092 17.3330051,9.85747961"
                id="Fill-12"
              ></path>
              <path
                d="M21.6629773,4.56571524 L23.6220976,4.56571524 L23.8928022,5.8038867 C24.9281778,4.74618501 25.8268056,4.29594084 26.8407852,4.29594084 C27.3356817,4.29594084 27.8556951,4.43175829 28.2371003,4.67920654 L27.4045207,6.68111863 C26.9979986,6.45785706 26.6147329,6.38994833 26.27798,6.38994833 C25.5588917,6.38994833 24.9281778,6.72763146 24.0035028,7.58253722 L24.0035028,15.2143619 L21.6629773,15.2143619 L21.6629773,4.56571524 Z"
                id="Fill-14"
              ></path>
              <path
                d="M28.4936651,9.85747961 C28.4936651,6.68158376 30.5662767,4.29640597 33.6947294,4.29640597 C36.800856,4.29640597 38.8725374,6.68158376 38.8725374,9.85747961 C38.8725374,13.0305847 36.800856,15.4399492 33.6947294,15.4399492 C30.5895331,15.4399492 28.4936651,13.0305847 28.4936651,9.85747961 M36.4622427,9.85747961 C36.4622427,7.74021571 35.4715194,6.20994369 33.6947294,6.20994369 C31.9151486,6.20994369 30.9039598,7.74021571 30.9039598,9.85747961 C30.9039598,11.9952092 31.9151486,13.5264114 33.6947294,13.5264114 C35.4715194,13.5264114 36.4622427,11.9952092 36.4622427,9.85747961"
                id="Fill-16"
              ></path>
              <path
                d="M40.7950056,4.56571524 L42.7755217,4.56571524 L43.0452961,5.82714312 C44.1485804,4.90432862 45.3634955,4.29594084 46.6919018,4.29594084 C48.3812477,4.29594084 50.0240808,5.19642918 50.0240808,8.12208602 L50.0240808,15.2143619 L47.6835553,15.2143619 L47.6835553,8.865361 C47.6835553,7.46997618 47.3691286,6.34436576 45.9504873,6.34436576 C44.9597641,6.34436576 43.9922973,6.90717097 43.1364613,7.62718954 L43.1364613,15.2143619 L40.7950056,15.2143619 L40.7950056,4.56571524 Z"
                id="Fill-18"
              ></path>
              <path
                d="M53.3452827,12.6918783 L53.3452827,6.41171633 L51.475467,6.41171633 L51.475467,4.56515708 L53.3452827,4.56515708 L53.3452827,2.11207056 L55.6858082,1.39298225 L55.6858082,4.56515708 L58.781702,4.56515708 L58.781702,6.41171633 L55.6858082,6.41171633 L55.6858082,12.1746557 C55.6858082,13.1430527 56.0448872,13.5263184 56.7435099,13.5263184 C57.3974802,13.5263184 57.9816813,13.2779399 58.4086691,12.9858394 L59.1538046,14.605416 C58.4086691,15.1235689 57.2384064,15.4398561 56.1797744,15.4398561 C54.4029844,15.4398561 53.3452827,14.4249462 53.3452827,12.6918783"
                id="Fill-20"
              ></path>
              <path
                d="M59.6372589,9.85747961 C59.6372589,6.68158376 61.70801,4.29640597 64.8383233,4.29640597 C67.9444499,4.29640597 70.0133405,6.68158376 70.0133405,9.85747961 C70.0133405,13.0305847 67.9444499,15.4399492 64.8383233,15.4399492 C61.7303362,15.4399492 59.6372589,13.0305847 59.6372589,9.85747961 M67.6058365,9.85747961 C67.6058365,7.74021571 66.6160436,6.20994369 64.8383233,6.20994369 C63.0596728,6.20994369 62.0447629,7.74021571 62.0447629,9.85747961 C62.0447629,11.9952092 63.0596728,13.5264114 64.8383233,13.5264114 C66.6160436,13.5264114 67.6058365,11.9952092 67.6058365,9.85747961"
                id="Fill-22"
              ></path>
              <path
                d="M86.5184173,9.85747961 C86.5184173,6.63693144 88.2961375,4.29640597 91.1790026,4.29640597 C94.0841938,4.29640597 95.749353,6.45832219 95.749353,9.90120167 L95.749353,10.4649371 L88.9045253,10.4649371 C88.9482474,12.2873097 90.1436271,13.5701335 92.0804212,13.5701335 C93.1353321,13.5701335 93.9930287,13.2789632 94.7576995,12.8510452 L95.4553919,14.4482957 C94.4200164,15.1236619 93.0916101,15.4399492 91.8311124,15.4399492 C88.1175283,15.4399492 86.5184173,12.8054626 86.5184173,9.85747961 Z M93.3613845,8.79884766 C93.3167321,7.4471849 92.663692,6.11877854 91.1548159,6.11877854 C89.7371049,6.11877854 88.9045253,7.35695001 88.8812689,8.79884766 L93.3613845,8.79884766 Z"
                id="Fill-24"
              ></path>
              <path
                d="M103.85719,14.1335899 C103.069263,14.8759346 102.08133,15.4396701 100.751994,15.4396701 C98.7491513,15.4396701 97.286788,14.1103335 97.286788,12.0628388 C97.286788,10.6451278 97.9844804,9.72045282 99.063578,9.18090403 C99.806853,8.79856858 100.705481,8.66275113 101.786439,8.61809881 L103.702767,8.55112034 L103.702767,8.10087617 C103.702767,6.70456109 102.825536,6.11849947 101.562247,6.11849947 C100.437567,6.11849947 99.4691699,6.47943901 98.7258949,6.90735702 L97.9137809,5.35289833 C99.1761391,4.63381002 100.527802,4.29612689 101.923187,4.29612689 C104.557673,4.29612689 106.041432,5.5119722 106.041432,8.10087617 L106.041432,12.197726 C106.041432,13.727998 106.087015,14.6517428 106.244228,15.2136177 L104.240456,15.2136177 L103.85719,14.1335899 Z M102.100866,10.2162796 C101.336195,10.2376755 100.794785,10.352097 100.391054,10.5772191 C99.8970879,10.8460632 99.6505699,11.3195638 99.6505699,11.9270214 C99.6505699,12.9400707 100.324076,13.5698544 101.381777,13.5698544 C102.460875,13.5698544 103.274849,12.9400707 103.702767,12.5586656 L103.702767,10.1474406 L102.100866,10.2162796 Z"
                id="Fill-26"
              ></path>
              <path
                d="M108.63015,4.56571524 L110.58834,4.56571524 L110.859045,5.8038867 C111.894421,4.74618501 112.795839,4.29594084 113.807028,4.29594084 C114.302855,4.29594084 114.819147,4.43175829 115.203343,4.67920654 L114.371694,6.68111863 C113.964241,6.45785706 113.583766,6.38994833 113.247014,6.38994833 C112.525134,6.38994833 111.894421,6.72763146 110.972536,7.58253722 L110.972536,15.2143619 L108.63015,15.2143619 L108.63015,4.56571524 Z"
                id="Fill-28"
              ></path>
              <path
                d="M124.986851,9.85747961 C124.986851,6.68158376 127.057602,4.29640597 130.186055,4.29640597 C133.294042,4.29640597 135.363863,6.68158376 135.363863,9.85747961 C135.363863,13.0305847 133.294042,15.4399492 130.186055,15.4399492 C127.080859,15.4399492 124.986851,13.0305847 124.986851,9.85747961 M132.956359,9.85747961 C132.956359,7.74021571 131.965636,6.20994369 130.186055,6.20994369 C128.407405,6.20994369 127.395285,7.74021571 127.395285,9.85747961 C127.395285,11.9952092 128.407405,13.5264114 130.186055,13.5264114 C131.965636,13.5264114 132.956359,11.9952092 132.956359,9.85747961"
                id="Fill-30"
              ></path>
              <path
                d="M137.286424,4.56571524 L139.267871,4.56571524 L139.536715,5.82714312 C140.64186,4.90432862 141.856775,4.29594084 143.184251,4.29594084 C144.872666,4.29594084 146.515499,5.19642918 146.515499,8.12208602 L146.515499,15.2143619 L144.174974,15.2143619 L144.174974,8.865361 C144.174974,7.46997618 143.860547,6.34436576 142.441906,6.34436576 C141.451183,6.34436576 140.482786,6.90717097 139.62788,7.62718954 L139.62788,15.2143619 L137.286424,15.2143619 L137.286424,4.56571524 Z"
                id="Fill-32"
              ></path>
              <path
                d="M122.52409,6.74195741 C121.772443,6.42287941 120.940793,6.16333783 119.946349,6.16333783 C119.037488,6.16333783 118.31933,6.45822916 118.31933,7.28987851 C118.31933,9.15411264 123.520395,8.46107151 123.520395,12.0174423 C123.520395,14.4165739 121.521273,15.4398561 119.221679,15.4398561 C117.645825,15.4398561 116.36114,14.9440294 115.552747,14.4482027 L116.273696,12.7151347 C117.015111,13.187705 117.983508,13.5700405 119.109118,13.5700405 C120.284963,13.5700405 121.202195,13.1356107 121.202195,12.0639551 C121.202195,10.1550687 116.023457,10.8267139 116.023457,7.42476571 C116.023457,5.62378904 117.493263,4.29631294 119.995653,4.29631294 C121.194753,4.29631294 122.273851,4.50469041 123.13899,4.83307097 L122.52409,6.74195741 Z"
                id="Fill-34"
              ></path>
              <polygon
                id="Fill-36"
                points="4.29973879 2.50901103 9.30256549e-05 2.50901103 9.30256549e-05 0.572216899 10.9640967 0.572216899 10.9640967 2.50901103 6.66352068 2.50901103 6.66352068 15.2135247 4.29973879 15.2135247"
              ></polygon>
              <path
                d="M83.1426093,1.24721105 C82.2597958,0.78394329 81.1862798,0.571844797 79.722056,0.571844797 L76.1889416,0.571844797 L76.1889416,15.2140829 L78.5527235,15.2140829 L78.5527235,10.5088452 L79.5127482,10.5088452 C80.8858069,10.5088452 81.9109496,10.297677 82.7239938,9.94976106 C84.7714885,9.09020401 85.5398804,7.3199258 85.5398804,5.52918194 C85.5398804,3.50494369 84.6552064,2.03978963 83.1426093,1.24721105 M81.6607106,8.16831977 C81.1853495,8.43065212 80.541612,8.57205112 79.6867062,8.57205112 L78.5527235,8.57205112 L78.5527235,2.50956919 L79.6625195,2.50956919 C80.5909156,2.50956919 81.2569793,2.67515485 81.7555968,2.96167387 C82.6365497,3.48447805 83.0635375,4.41194383 83.0635375,5.52918194 C83.0635375,6.78874931 82.6114328,7.71621509 81.6607106,8.16831977"
                id="Fill-38"
              ></path>
            </g>
            <g
              id="Group-5"
              transform="translate(163.000000, 0.000000)"
              fill-rule="nonzero"
            >
              <path
                d="M46.7431111,9.94793889 C42.9140889,8.74940556 37.7765556,7.81852222 34.9216222,7.67992778 C30.76435,7.48522222 28.3274444,8.52103333 27.7730667,12.6059222 C26.9948056,18.3230833 27.0138833,23.5717167 27.2933167,29.4605778 C27.3314722,30.1984389 27.8819222,30.7505722 28.6719667,30.7068056 C29.4479833,30.6630389 29.9894556,30.0873389 29.96645,29.3124444 C29.8054111,24.1232889 29.5209278,17.9465778 30.1246833,12.85225 C30.2638389,11.6716722 30.5533722,10.8591833 30.9742056,10.2958278 C35.4249389,13.2556889 38.8465944,16.4467278 42.2766667,20.4738222 C42.9511222,21.2638667 43.5234556,21.1061944 43.85395,20.6662833 C44.2764667,20.1034889 44.02565,19.5092722 43.5604889,18.9290833 C40.2504944,14.7993056 36.7306444,11.78165 32.6008667,9.27292222 C33.2545611,9.11749444 34.01655,9.10458889 34.8711222,9.13320556 C37.5672611,9.22747222 42.7294833,10.1448889 46.8643111,11.3080722 C47.2565278,11.4186111 47.5337167,11.2199778 47.5219333,10.80195 C47.5073444,10.3687722 47.1948056,10.0910222 46.7431111,9.94793889"
                id="Shape"
                fill="#B3DB11"
              ></path>
              <path
                d="M23.7482167,32.5983111 C24.4843944,32.4064111 25.0696333,31.7033389 24.9007389,30.8970222 C24.0293333,26.7403111 23.2005722,22.2884556 22.7561722,18.2383556 C22.5811056,16.6610722 22.0805944,15.2768111 20.2221944,14.9435111 C18.6954111,14.6724944 16.6221056,15.3957667 14.5920056,16.6246 C9.64076111,19.6220556 5.01215556,24.0570778 2.10672222,29.5122 C1.75490556,30.1743111 1.9238,30.8106111 2.21108889,31.2538889 C2.65997778,31.9592056 3.13860556,31.8800889 3.41523333,31.3374944 C6.22415556,25.8329944 10.8078722,21.0551333 15.7389167,18.0571167 C17.4654556,17.0055944 18.5152944,16.7884444 18.9933611,16.8647556 C19.8142667,16.9960556 20.1436389,17.6890278 20.27045,19.0098833 C20.6615444,23.0622278 21.26025,27.5584111 22.1153833,31.6539611 C22.2708111,32.39575 22.8319222,32.8373444 23.7482167,32.5983111"
                id="Shape"
                fill="#4DBDD7"
              ></path>
              <path
                d="M20.6026278,8.01996111 C20.3579833,7.27929444 19.05845,7.24001667 16.4515278,7.42742778 C13.8373111,7.61315556 11.3010889,7.98405 8.31541667,8.70395556 C7.66565,8.86331111 7.23527778,9.10739444 6.96482222,9.48951111 C6.6506,9.93447222 6.88626667,10.1572333 7.37723889,10.0365944 C10.0531778,9.38514444 13.8384333,8.76118889 16.5704833,8.6658 C16.6535278,8.66411667 16.7304,8.66243333 16.8011,8.66075 C11.4907444,10.3861667 6.23930556,12.9111667 1.9339,16.5095722 C1.47266667,16.8973 1.34305,17.21545 1.32846111,17.6155222 C1.3077,18.08405 1.51138333,18.2927833 1.83963333,18.0184 C6.35601667,14.2415611 12.6337278,11.3411778 17.5524278,9.58153333 C17.4407667,9.68702222 17.2954389,9.79812222 17.1052222,9.92325 C10.4504444,14.2123833 4.48639444,18.4801944 0.442466667,26.3026444 C0.17145,26.8328944 0.228683333,27.2722444 0.429,27.6100333 C0.651761111,27.9859778 1.01423889,28.0033722 1.20557778,27.6352833 C5.38136667,19.5491111 11.1394889,15.4210167 18.2409111,10.6667222 C20.5347333,9.13096111 20.7984556,8.61025 20.6026278,8.01996111"
                id="Shape"
                fill="#FF0000"
              ></path>
              <path
                d="M47.0191778,7.91952222 C46.9327667,7.65523889 46.6583833,7.30061667 46.0826833,7.13733333 C42.1498556,6.02240556 38.2809944,5.40911111 34.7100833,5.0803 C34.5434333,5.06458889 34.3756611,5.05617222 34.2135,5.04551111 C36.7929278,4.35366111 39.6798444,3.93675556 42.3125778,3.59896667 C42.5706889,3.5653 42.8243111,3.34253889 42.4758611,3.00811667 C42.2059667,2.75112778 41.8227278,2.65237222 41.3261444,2.69838333 C38.6956556,2.94022222 35.81435,3.5047 33.3291889,4.24761111 C33.2960833,4.25602778 33.2680278,4.26725 33.2394111,4.27903333 C33.3954,4.10116111 33.6164778,3.89579444 33.8454111,3.68201111 C35.4586056,2.16813333 34.9317222,1.18899444 33.8622444,0.768722222 C32.4359,0.209294444 30.7357333,0.399511111 29.7268556,0.882627778 C29.3991667,1.0403 29.4855778,1.34442222 29.8996778,1.53968889 C30.2430778,1.70016667 30.8030667,1.62273333 31.1560056,1.51892778 C31.7967944,1.33039444 32.2602722,1.32253889 32.7865944,1.52397778 C33.4722722,1.78713889 33.3634167,2.29887222 32.3786667,3.12763333 C31.5751556,3.81836111 30.9388556,4.49393889 31.6004056,5.18242222 C32.20865,5.81928333 33.4492667,6.05438889 34.4031556,6.17053889 C38.0240056,6.60315556 42.3569056,7.26526667 46.53045,8.43069444 C46.9221056,8.53898889 47.1415,8.29715 47.0191778,7.91952222"
                id="Shape"
                fill="#FFB300"
              ></path>
              <path
                d="M23.1158444,5.62850556 C27.1956833,5.42762778 27.8808,4.37217778 28.7084389,3.18991667 C28.8066333,3.04851667 28.9081944,2.9189 29.0103167,2.79826111 C29.2386889,2.53341667 29.0669889,2.18833333 28.5720889,2.07891667 C28.1310556,1.98240556 27.5660167,2.08901667 27.2433778,2.44868889 C27.1771667,2.52275556 27.1109556,2.60187222 27.0425,2.68379444 C26.3658,3.52546111 25.8013222,4.54107222 22.9536833,4.63477778 C18.9843833,4.76663889 15.5745111,5.1611 12.6067944,5.69135 C11.8302167,5.83050556 11.4284611,6.13462778 11.2146778,6.35795 C10.9689111,6.61325556 10.9268278,6.91232778 11.4391222,6.82367222 C15.2210111,6.16829444 18.6954111,5.84621667 23.1158444,5.62850556"
                id="Shape"
                fill="#00184A"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "CorpLogo",

  computed: {
    styleProps() {
      const display: string = "flex"
      const flexDirection: string = "column"
      const { minWidth = "18rem", minHeight = "15%" } = this
      return {
        display,
        flexDirection,
        minWidth,
        minHeight,
      }
    },
  },
})
</script>

