<script setup lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n-bridge"

const { t } = useI18n()
</script>

<template>
  <div class="CaptchaBadge" role="button">
    <div class="CaptchaBadge-Image">
      <img
        src="https://www.gstatic.com/recaptcha/api2/logo_48.png"
        width="48"
        height="48"
        alt="Image for CAPTCHA"
      />
      <div class="CaptchaBadge-Image-Links">
        <span>{{ t(`captcha.privacy`) }}</span>
        <span>-</span>
        <span>{{ t(`captcha.terms`) }}</span>
      </div>
    </div>
    <div class="CaptchaBadge-Expanded">
      <div>{{ t(`captcha.statement`) }}</div>
      <div class="CaptchaBadge-Expanded-Links">
        <a v-if="$root.$i18n.locale === 'fr'"
          target="_blank"
          href="https://www.google.com/intl/fr-CA/policies/privacy/"
          >{{ t(`captcha.privacy`) }}</a
        >
        <a v-else
          target="_blank"
          href="https://www.google.com/intl/en/policies/privacy/"
          >{{ t(`captcha.privacy`) }}</a
        >
        <span>-</span>
        <a v-if="$root.$i18n.locale === 'fr'"
          target="_blank"
          href="https://www.google.com/intl/fr-CA/policies/terms/"
          >{{ t(`captcha.terms`) }}</a
        >
        <a v-else
          target="_blank"
          href="https://www.google.com/intl/en/policies/terms/"
          >{{ t(`captcha.terms`) }}</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "CaptchaBadge",
})
</script>

<style lang="scss" scoped>
.CaptchaBadge {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 58px;
  right: -12.1rem;
}
.CaptchaBadge:hover {
  transition: 0.3s;
  right: 0.1rem;
}

.CaptchaBadge-Image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px 0 0;
  cursor: pointer;
}

.CaptchaBadge-Image-Image {
  width: 48px;
  height: 48px;
  background: url(https://www.gstatic.com/recaptcha/api2/logo_48.png);
  background-repeat: no-repeat;
}

.CaptchaBadge-Image-Links {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  font-family: "Roboto", helvetica, arial, sans-serif;
  font-size: 5px;
  font-weight: 400;
}
.CaptchaBadge-Image-Links span {
  max-width: 2rem;
  max-height: 0.8rem;
  text-overflow: clip;
}

.CaptchaBadge-Expanded {
  min-width: 12rem;
  background: #1a73e8;
  color: white;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
}

.CaptchaBadge-Expanded-Links {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
}
.CaptchaBadge-Expanded-Links a {
  color: white;
  max-width: 6rem;
  max-height: 0.8rem;
  text-overflow: clip;
}
</style>
