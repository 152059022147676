<script setup lang="ts">
import { defineComponent } from "vue"

import { mapState } from "pinia"
import { usePublicStatusStore } from "../stores/publicStatus"

import { useI18n } from "vue-i18n-bridge"

const { t } = useI18n()
</script>

<template>
  <NotificationDialog ref="notificationDialog" />
</template>

<script lang="ts">
export default defineComponent({
  name: "WatchPublicStatus",

  computed: {
    ...mapState(usePublicStatusStore, ["getPublicStatus"]),
  },

  watch: {
    getPublicStatus: {
      async handler(newValue) {
        const disconnectParams = {
          clearContext: false,
          clearStatus: true,
          clearSocket: true,
          reconnectSocket: false
        }
        if (["DEP"].includes(newValue)) {
          setTimeout(() => {
            this.$root.$emit("disconnectWebSocket", disconnectParams)
          }, 500)
          // dialog's title is intentionally blank
          await this.$refs.notificationDialog.open(
            "",
            this.$t("confirmations.departure")
          )
        } else if (["CAN"].includes(newValue)) {
          setTimeout(() => {
            this.$root.$emit("disconnectWebSocket", disconnectParams)
          }, 500)
          await this.$refs.notificationDialog.open(
            "",
            this.$t("confirmations.cancellation")
          )
        }
      },
    },
  },
})
</script>

