<script lang="ts">
import { defineComponent, onBeforeMount } from "vue"

import { isNavigationFailure, NavigationFailureType } from "vue-router"

import { useBackendStore } from "./stores/backend"
import { useFlightStore } from "./stores/flight"
import { useMessageStore } from "./stores/messages"
import { userContactsStore } from "./stores/userContacts"

import { version } from "../package.json"

export default defineComponent({
  setup() {
    onBeforeMount(async () => {
      console.log(`app version: ${version}`)

      const messages = useMessageStore()
      messages.initialize()

      const userContacts = userContactsStore()
      userContacts.initialize()
    })
  },

  mounted() {
    const reconnectDelay: number = 1000

    const backend = useBackendStore()
    const flights = useFlightStore()
    if (flights.isLoaded && !backend.isSocketConnected) {
      const {
        getMostRecentFlight: {
          flightid = "",
          estimatedtime,
          scheduledtime,
          publicstatus,
        } = {},
      } = flights
      const batPlusDiff: number = 2
      const batPlusMinutes: Date = new Date(new Date(estimatedtime).getTime() + batPlusDiff*60000)
      const isDeparted: boolean = new Date() > batPlusMinutes
      if (!(["CAN", "DEP"].includes(publicstatus) && !isDeparted)) {
        setTimeout(() => {
          this.$root.$emit("connectWebSocket", { flightId: flightid })
        }, reconnectDelay)
        this.$router.replace({ name: "flight" }).catch((error) => {
          if (!isNavigationFailure(error, NavigationFailureType.duplicated))
            throw error
        })
      }
    }
  },
})
</script>

<template>
  <v-app id="app">
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <BottomBar />
    <FlightUpdateAlert />
    <SocketConnect />
    <WatchPublicStatus />
    <WatchRollOff />
    <SharedCaptcha />
    <AppVisibility />
  </v-app>
</template>

<style lang="scss">
html {
  @media all and (max-width: 600px), all and (max-height: 450px) {
    font-size: 0.9rem;

    .v-label {
      font-size: 0.9rem;
    }

    .v-input {
      font-size: 0.9rem;
    }
  }

  font-size: 1rem;
  overflow: hidden;
}

.v-main {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.v-main__wrap {
  display: flex;
}
</style>

